var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('gw-text-field',{attrs:{"maxlength":200,"loading":_vm.loading,"label":"Product Name","outlined":"","dense":"","hide-details":"","rules":_vm.rules.textFill},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"product.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Collection","color":"secondary","items":_vm.collectionItem,"item-text":"name","item-value":"id","outlined":"","dense":"","hide-details":"","loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.product.gwCollection),callback:function ($$v) {_vm.$set(_vm.product, "gwCollection", $$v)},expression:"product.gwCollection"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-autocomplete',{attrs:{"items":_vm.groupCategoryItem,"loading":_vm.loading,"label":"Group Categories","item-text":"name","item-value":"name","outlined":"","dense":"","hide-details":"","multiple":"","small-chips":"","clearable":"","deletable-chips":"","return-object":false},on:{"input":function($event){return _vm.groupCategoryHandle($event)}},model:{value:(_vm.product.groupCategories),callback:function ($$v) {_vm.$set(_vm.product, "groupCategories", $$v)},expression:"product.groupCategories"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-autocomplete',{attrs:{"items":_vm.prototypeCategoriesItem,"loading":_vm.loading,"label":"Report Categories","item-text":"name","item-value":"name","return-object":false,"outlined":"","dense":"","hide-details":""},model:{value:(_vm.product.productPrototype.category),callback:function ($$v) {_vm.$set(_vm.product.productPrototype, "category", $$v)},expression:"product.productPrototype.category"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('gw-autocomplete',{attrs:{"items":_vm.categoriesItem,"rules":{
        required: true,
        oneOf: _vm.categoriesItem.map((r) => r.name)
      },"loading":_vm.loading,"height":32,"label":"Web categories","item-text":"name","item-value":"name","multiple":"","small-chips":"","clearable":"","deletable-chips":"","return-object":false,"outlined":"","dense":"","hide-details":""},model:{value:(_vm.product.categories),callback:function ($$v) {_vm.$set(_vm.product, "categories", $$v)},expression:"product.categories"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('gw-combobox',{attrs:{"items":_vm.brandsItem,"rules":{
        required: true,
        oneOf: _vm.brandsItem.map((r) => r.name)
      },"loading":_vm.loading,"label":"Brand","item-text":"name","item-value":"name","outlined":"","dense":"","hide-details":""},model:{value:(_vm.product.brand),callback:function ($$v) {_vm.$set(_vm.product, "brand", $$v)},expression:"product.brand"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-text-field',{attrs:{"rules":{
        required: true,
        integer: true
      },"number":"","label":"Price","outlined":"","dense":"","hide-details":"","disabled":_vm.loading},model:{value:(_vm.product.price),callback:function ($$v) {_vm.$set(_vm.product, "price", $$v)},expression:"product.price"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }