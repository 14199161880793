<template>
  <v-row>
    <v-col cols="12">
      <gw-text-field
        v-model.trim="product.name"
        :maxlength="200"
        :loading="loading"
        label="Product Name"
        outlined
        dense
        hide-details
        :rules="rules.textFill" />
    </v-col>
    <v-col cols="12">
      <v-autocomplete
        v-model="product.gwCollection"
        label="Collection"
        color="secondary"
        :items="collectionItem"
        item-text="name"
        item-value="id"
        outlined
        dense
        hide-details
        :loading="loading"
        :disabled="loading" />
    </v-col>
    <v-col cols="12">
      <gw-autocomplete
        v-model="product.groupCategories"
        :items="groupCategoryItem"
        :loading="loading"
        label="Group Categories"
        item-text="name"
        item-value="name"
        outlined
        dense
        hide-details
        multiple
        small-chips
        clearable
        deletable-chips
        :return-object="false"
        @input="groupCategoryHandle($event)" />
    </v-col>
    <v-col cols="12">
      <gw-autocomplete
        v-model="product.productPrototype.category"
        :items="prototypeCategoriesItem"
        :loading="loading"
        label="Report Categories"
        item-text="name"
        item-value="name"
        :return-object="false"
        outlined
        dense
        hide-details />
    </v-col>
    <v-col cols="6">
      <gw-autocomplete
        v-model="product.categories"
        :items="categoriesItem"
        :rules="{
          required: true,
          oneOf: categoriesItem.map((r) => r.name)
        }"
        :loading="loading"
        :height="32"
        label="Web categories"
        item-text="name"
        item-value="name"
        multiple
        small-chips
        clearable
        deletable-chips
        :return-object="false"
        outlined
        dense
        hide-details />
    </v-col>
    <v-col cols="6">
      <gw-combobox
        v-model="product.brand"
        :items="brandsItem"
        :rules="{
          required: true,
          oneOf: brandsItem.map((r) => r.name)
        }"
        :loading="loading"
        label="Brand"
        item-text="name"
        item-value="name"
        outlined
        dense
        hide-details />
    </v-col>
    <v-col cols="12">
      <gw-text-field
        v-model="product.price"
        :rules="{
          required: true,
          integer: true
        }"
        number
        label="Price"
        outlined
        dense
        hide-details
        :disabled="loading" />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    categoriesItem: {
      type: Array,
      default: () => []
    },
    groupCategoryItem: {
      type: Array,
      default: () => []
    },
    prototypeCategoriesItem: {
      type: Array,
      default: () => []
    },
    brandsItem: {
      type: Array,
      default: () => []
    },
    collectionItem: {
      type: Array,
      default: () => []
    },
    tagsItem: {
      type: Array,
      default: () => []
    },
    value: {
      type: null,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      product: {},
      files: [],
      rules: {
        textFill: {
          required: true
        },
        numberFill: {
          regex: /^[+-]?([0-9]*[.])?[0-9]+$/
        }
      },
      sizeChart: {},
      isPreOrder: false
    }
  },
  watch: {
    product: {
      handler (val) {
        this.$emit('input', val)
      },
      deep: true
    },
    value: {
      handler (val) {
        this.product = val
      },
      deep: true
    }
  },
  async created () {
    if (this.value) {
      this.product = this.value
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    groupCategoryHandle (groupCategories, limit = 20) {
      if (groupCategories.length > 20) {
        this.product.groupCategories = groupCategories.slice(0, limit)

        this.setSnackbar({
          value: true,
          message: 'ไม่สามารถเลือก Group Categories ได้เกิน 20 Group Category',
          type: 'warning'
        })
      }
    },
    tagsHandle (tags, limit = 20) {
      if (tags.length > 20) {
        this.product.tags = tags.slice(0, limit)

        this.setSnackbar({
          value: true,
          message: 'ไม่สามารถเลือก Tags ได้เกิน 20 Tags',
          type: 'warning'
        })
      }
    },
    togglePreOrder () {
      this.isPreOrder = this.product.preOrder
    }
  }
}
</script>

<style scoped>
.testtset {
  width: 100%;
  height: 250px;
  border: 1px dotted;
  border-radius: 25px;
}
.input-skeleton-loader .v-skeleton-loader__image {
  width: 100%;
  height: 100%;
}
</style>
