<template>
  <validation-observer
    ref="productform"
    tag="form"
    @submit.prevent="submitForm()">
    <v-container fluid>
      <div class="product-toolbar d-flex flex-row justify-space-between align-center elevation-3 mb-3">
        <span>
          {{
            loading ? 'Loading...' : `${product.name} | ${product.model}`
          }}
        </span>
        <div
          class="d-flex flex-row align-center"
          style="gap: 0.625rem;">
          <v-btn
            v-if="isEditProduct && isRemoveable"
            color="error"
            outlined
            rounded
            small
            :loading="loading"
            @click="removeProduct(product.id)">
            {{ $t('button.Remove') }}
          </v-btn>
          <v-btn
            color="success"
            rounded
            small
            type="submit"
            :loading="loading">
            {{ $t('button.Save') }}
          </v-btn>
        </div>
      </div>
      <v-row class="px-8">
        <v-col
          cols="12"
          class="card-container elevation-3">
          <v-row>
            <v-col
              cols="12"
              class="card-title">
              Product Details
            </v-col>
            <v-col cols="12">
              <form-product-info
                v-model="product"
                :categories-item="productAttributes.categories"
                :brands-item="productAttributes.brands"
                :tags-item="productAttributes.tags"
                :collection-item="productAttributes.collections"
                :group-category-item="productAttributes.groupCategories"
                :prototype-categories-item="productAttributes.prototypeCategories"
                :buy-the-looks="buyTheLookItems"
                :loading="loading" />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="card-container elevation-3">
          <v-row>
            <v-col
              cols="12"
              class="card-title">
              Product Images
            </v-col>
            <v-col cols="12">
              <gw-drop-file
                v-model="product.photoUrls"
                :loading="loading"
                :img-aspect-ratio="0.75"
                s3-path="/product-images"
                multiple />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="card-container elevation-3">
          <v-row>
            <v-col
              cols="12"
              class="card-title">
              Product Tags
            </v-col>
            <v-col cols="12">
              <gw-autocomplete
                v-model="product.tags"
                :items="productAttributes.tags"
                :loading="loading"
                label="Tags"
                item-text="name"
                item-value="name"
                multiple
                small-chips
                clearable
                deletable-chips
                outlined
                dense
                hide-details
                :return-object="false"
                @input="tagsHandle($event)" />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="card-container elevation-3">
          <v-row>
            <v-col
              cols="12"
              class="card-title">
              Product Buy The Looks
            </v-col>
            <v-col cols="12">
              <product-modal
                v-model="buyTheLookModal"
                @on-save="onSaveBuyTheLook($event)" />
              <v-skeleton-loader
                :loading="loading"
                :height="230"
                type="image"
                class="input-skeleton-loader">
                <buy-the-look-input
                  v-model="buyTheLookModalItems"
                  @on-remove="onRemoveBuyTheLook($event)"
                  @more-product-click="openBuyTheLookModal()" />
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="card-container elevation-3">
          <v-row>
            <v-col
              cols="12"
              class="card-title">
              Product Skus
            </v-col>
            <v-col cols="6">
              <form-table-skus
                v-model="skusTable"
                :disabled="isEditProduct"
                @remove-skus-table="removeSkusTable($event)" />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="card-container elevation-3">
          <v-row>
            <v-col
              cols="12"
              class="card-title">
              Product Size Chart
            </v-col>
            <v-col cols="12">
              <form-size-chart
                v-model="sizeChart"
                class="mb-5" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </validation-observer>
</template>

<script>
import { mapActions } from 'vuex'
import ProductProvider from '@/resources/ProductProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import SizeChartProvider from '@/resources/SizeChartProvider'
import PrototypeCategoryProvider from '@/resources/PrototypeCategoryProvider'
import getImageOrGradientCss from '@/assets/js/GetImageOrGradientCss'
import SizeValues from '@/assets/js/SizeValues'
import { getRole } from '@/assets/js/Authentication'
import FormProductInfo from '../components/FormProductInfo.vue'
import FormTableSkus from '../components/FormTableSkus.vue'
import SizeChart from '../components/SizeChart.vue'
import BuyTheLookInput from '../components/BuyTheLookInput.vue'
import ProductModal from '../components/ProductModal.vue'

const ProductService = new ProductProvider()
const ProductAttributeService = new ProductAttributeProvider()
const SizeChartService = new SizeChartProvider()
const PrototypeCategoryService = new PrototypeCategoryProvider()

export default {
  components: {
    formProductInfo: FormProductInfo,
    formTableSkus: FormTableSkus,
    formSizeChart: SizeChart,
    BuyTheLookInput,
    ProductModal
  },
  data () {
    return {
      loading: false,
      buyTheLookModal: false,
      buyTheLookModalItems: false,
      buyTheLookItems: [],
      productAttributes: {
        brands: [],
        categories: [],
        colors: [],
        size: [],
        tags: [],
        collections: [],
        groupCategories: [],
        prototypeCategories: []
      },
      product: {
        additionalAttributes: [],
        brand: '',
        buyTheLooks: [],
        categories: [],
        codeTemp: '',
        colors: [],
        description: '',
        groupCategories: [],
        gwCollection: null,
        isOnWeb: false,
        model: '',
        name: '',
        note: '',
        options: [],
        photoUrls: [],
        preOrder: false,
        preOrderNote: '',
        price: 0,
        priority: 99999,
        productPrototype: {
          id: '',
          category: '',
          subCategory: '',
          designer: '',
          merchandiser: '',
          factory: '',
          margin: 2.8
        },
        sizeChartUrl: '',
        sizes: [],
        skuCodeTemps: [],
        skuModels: [],
        skus: [],
        specialPrice: 0,
        tags: [],
        variant: 'SKU',
        videoUrl: '',
        width: 1,
        height: 1,
        length: 1,
        weight: 1
      },
      skusTable: [],
      sizeChart: {
        productId: '',
        sizeChart: [{
          header: '',
          rows: [{
            key: '',
            value: ''
          }]
        }],
        sizeUnit: ''
      },
      createdSizeChart: false
    }
  },
  computed: {
    isEditProduct () {
      return this.$route.name === 'EditProduct' && !!this.$route.params.productId
    },
    isRemoveable () {
      const role = getRole()

      return this.skusTable.every((sku) => !sku.gotStock) && role === 'developer'
    }
  },
  created () {
    if (this.$route.name === 'CreateProduct') {
      this.initCreateProductPage()
    } else if (this.isEditProduct) {
      this.initEditProductPage(this.$route.params.productId)
    } else {
      this.setErrorPage(404)
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    async initCreateProductPage () {
      try {
        this.loading = true

        await this.getAttributes()

        this.sizeChart = {
          productId: '',
          sizeChart: [{
            header: '',
            rows: [{
              key: '',
              value: ''
            }]
          }],
          sizeUnit: ''
        }
      } catch (error) {
        console.error('initCreateProductPage: ', error)
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
      }
    },
    async initEditProductPage (paramId) {
      try {
        this.loading = true

        const [product, sizeChartResponse] = await Promise.all([
          this.getProduct(paramId),
          this.getSizeChart(paramId),
          this.getAttributes()
        ])

        await this.mapProductData({
          ...product,
          productPrototype: product?.productPrototype || {
            id: '',
            category: '',
            subCategory: '',
            designer: '',
            merchandiser: '',
            factory: '',
            margin: 2.8
          }
        })

        this.sizeChart = sizeChartResponse || []
      } catch (error) {
        console.error('initEditProductPage: ', error)
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
      }
    },
    async getSizeChart (id) {
      this.createdSizeChart = false

      const { data } = await SizeChartService.getSizeChartByProductId(id)

      if (!data || !data.id) {
        return {
          productId: id,
          sizeChart: [{
            header: '',
            rows: [{
              key: '',
              value: ''
            }]
          }],
          sizeUnit: ''
        }
      }

      this.createdSizeChart = true

      return data
    },
    sortSize (data) {
      return data.sort((a, b) => SizeValues(a.size.name) - SizeValues(b.size.name))
    },
    async getProduct (id) {
      const { data } = await ProductService.getProductById(id)

      if (!data || !data.id) {
        const error = {
          code: 404,
          message: 'Product not found'
        }
        throw error
      }

      return {
        ...data,
        skus: this.sortSize(data.skus)
      }
    },
    async fetchManyProductsById (productIds) {
      const { data } = await ProductService.getManyProductById({ productIds })

      return data
    },
    async mapProductData (data) {
      this.product = {
        ...this.product,
        ...data,
        gwCollection: data?.gwCollection || null,
        groupCategories: data?.groupCategories || [],
        preOrder: data?.preOrder || false,
        preOrderNote: data?.preOrderNote || ''
      }

      this.buyTheLookItems = await this.fetchManyProductsById(data.buyTheLooks)
      this.buyTheLookModalItems = this.buyTheLookItems

      this.skusTable = data.skus?.map((sku) => ({
        ...sku,
        gotStock: sku?.stock?.some((st) => st.onHandQty !== 0 || st.onReservedQty !== 0) || false
      }))
    },
    async createProduct (product) {
      try {
        this.loading = true

        const { data } = await ProductService.createProduct(product)

        this.sizeChart.productId = data.id
        await SizeChartService.createSizeChart(this.sizeChart)

        this.setSnackbar({
          value: true,
          message: this.$t('Product created'),
          type: 'success'
        })

        this.$router.push({ name: 'Products' })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
        console.error('createProduct', error)
      } finally {
        this.loading = false
      }
    },
    async updateProduct (product) {
      try {
        this.loading = true

        await ProductService.updateProduct(product.id, product)

        if (this.sizeChart && this.createdSizeChart) {
          await SizeChartService.updateSizeChart(this.sizeChart)
        } else if (this.sizeChart && this.sizeChart.sizeChart.length) {
          await SizeChartService.createSizeChart(this.sizeChart)
        }

        this.setSnackbar({
          value: true,
          message: this.$t('snackbar.Product updated'),
          type: 'success'
        })

        this.initEditProductPage(this.$route.params.productId)
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
        console.error('updateProduct', error)
      } finally {
        this.loading = false
      }
    },
    async removeProduct (id) {
      this.setModal({
        value: true,
        title: 'RemoveProduct',
        message: 'Do you want to remove the product',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveProduct(id)
      })
    },
    async confirmRemoveProduct (id) {
      try {
        const { data } = await ProductService.deleteProduct(id)
        const { data: sizeChartResponse } = await SizeChartService.getSizeChartByProductId(id)
        const { data: sizeChartDeleteResponse } = await SizeChartService.deleteSizeChart(id)

        if (sizeChartResponse && !sizeChartDeleteResponse) {
          const error = {
            code: 400,
            message: 'Size Chart Product ID not found or status not change'
          }
          throw error
        }

        if (data.id && data.status === 'deleted') {
          this.setSnackbar({
            value: true,
            message: this.$t('Product removed'),
            type: 'success'
          })
          this.$router.push({ name: 'Products' })
        } else {
          const error = {
            code: 400,
            message: 'Product ID not found or status not change'
          }
          throw error
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
        console.error('confirmRemoveProduct', error)
      }
    },
    async submitForm () {
      const valid = await this.$refs.productform.validate()

      if (valid) {
        const productData = {
          ...this.product,
          skus: this.skusTable.map((sku) => ({
            ...sku,
            price: this.product.price,
            images: this.product?.photoUrls?.length ? [this.product.photoUrls[0]] : []
          }))
        }

        if (this.isEditProduct) {
          this.setModal({
            value: true,
            title: 'UpdateProduct',
            message: 'Do you want to update the product',
            confirmText: 'Confirm',
            cancelText: 'Cancel',
            onConfirm: () => this.updateProduct(productData)
          })
        } else {
          this.setModal({
            value: true,
            title: 'CreateProduct',
            message: 'Do you want to create the product',
            confirmText: 'Confirm',
            cancelText: 'Cancel',
            onConfirm: () => this.createProduct(productData)
          })
        }
      } else {
        this.setSnackbar({
          value: true,
          message: 'กรุณากรอกฟิลด์ให้ครบถ้วน (cost and price)',
          type: 'error'
        })
      }
    },
    removeSkusTable (val) {
      this.skusTable.splice(val, 1)
    },
    async getAttributes () {
      const { data } = await ProductAttributeService.getAllProductAttribute()
      const { data: prototypeCategories } = await PrototypeCategoryService.getAllMapped()

      this.productAttributes = {
        ...this.productAttributes,
        colors: Array.isArray(data.colors) ? this.mapColor(data.colors) : [],
        brands: Array.isArray(data.brands) ? data.brands : [],
        size: Array.isArray(data.size) ? data.size : [],
        tags: Array.isArray(data.tags) ? data.tags : [],
        categories: Array.isArray(data.categories) ? data.categories : [],
        collections: Array.isArray(data.collections) ? [{ id: null, name: 'ไม่ระบุ' }, ...data.collections] : [],
        groupCategories: Array.isArray(data.groupCategories) ? data.groupCategories : [],
        prototypeCategories: prototypeCategories
          ?.map((d) => ({
            ...d,
            disabled: d.name === 'UNKNOWN'
          }))
          ?.sort((a, b) => a.id - b.id) || []
      }

      return data
    },
    mapColor (arr, itemsDisabled) {
      return arr.map((color) => ({
        ...color,
        label: color.name,
        children: color.childs && color.childs.length > 0 ? this.mapColor(color.childs, itemsDisabled) : undefined,
        gradientCss: getImageOrGradientCss(color)
      }))
    },
    onSaveBuyTheLook (products) {
      this.product.buyTheLooks = products.map((product) => product.id)
      this.buyTheLookModalItems = products
      this.buyTheLookModal = false
    },
    onRemoveBuyTheLook (index) {
      this.product.buyTheLooks.splice(index, 1)
      this.buyTheLookModalItems.splice(index, 1)
    },
    openBuyTheLookModal () {
      this.buyTheLookModal = true
    },
    tagsHandle (tags, limit = 20) {
      if (tags.length > 20) {
        this.product.tags = tags.slice(0, limit)

        this.setSnackbar({
          value: true,
          message: 'ไม่สามารถเลือก Tags ได้เกิน 20 Tags',
          type: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>
.skus-info {
  position: relative;
  background-color: #fbfbfb;
  padding: 20px 50px;
  margin-bottom: 5px;
}
.sku-bin {
  position: absolute;
  top: 5px;
  right: 5px;
}
.product-toolbar {
  position: sticky;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 5;
  background-color: #fff;
  padding: 1.25rem 1.875rem;
  border-radius: 0.625rem;
}
.product-toolbar span {
  font-weight: bold;
  font-size: 1.25rem;
}
.card-container {
  padding: 1.25rem 1.875rem;
  background-color: #fff;
  border-radius: 0.625rem;
  margin-top: 1.25rem;
}
.card-title {
  font-weight: bold;
  font-size: 1.35rem;
}
</style>
