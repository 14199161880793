<template>
  <v-row>
    <v-col :cols="12">
      <v-simple-table dense>
        <thead>
          <tr>
            <th
              v-for="(header, index) in tableHead"
              :key="`${header}-${index}`"
              :style="{
                textAlign: header.align
              }">
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in skusTable"
            :key="`skusTable-${index}`">
            <!-- <td>
              <v-switch
                v-model="skusTable[index]['status']"
                false-value="inactive"
                true-value="active" />
            </td> -->
            <td width="240px">
              <span>{{ item.code }}</span>
            </td>
            <td
              width="80px"
              class="text-center text-capitalize">
              <span>{{ item.color.name }}</span>
            </td>
            <td
              width="80px"
              class="text-center text-uppercase">
              <span>{{ item.size.name }}</span>
            </td>
            <!-- <td width="100px">
              <v-switch
                v-model="skusTable[index]['preOrder']"
                :true-value="true"
                :false-value="false" />
            </td>
            <td>
              <v-text-field
                v-model="skusTable[index]['preOrderNote']"
                :disabled="!item.preOrder" />
            </td> -->
            <!-- <td class="disabled-label">
              <gw-text-field
                v-model="skusTable[index]['cost']"
                number
                hide-details />
            </td> -->
            <!-- <td class="disabled-label">
              <gw-text-field
                v-model="skusTable[index]['price']"
                :rules="rules.numberFill"
                :placeholder="placeholder"
                label="Price"
                number
                hide-details />
            </td> -->
            <!-- <td class="disabled-label">
              <gw-text-field
                v-model="skusTable[index]['ratio']"
                placeholder="2.8"
                hide-details />
            </td> -->
            <!-- <td>
              <gw-icon-hover
                v-if="!disabled"
                class="sku-table-bin"
                icon-name="mdi-delete"
                icon-hover="mdi-delete-empty"
                @click="removeSkusTable(index)" />
            </td> -->
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: null,
      default: () => []
    },
    placeholder: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableHead: [
        // 'Availability',
        {
          text: 'Code',
          value: 'code',
          align: 'start'
        },
        {
          text: 'Color',
          value: 'color',
          align: 'center'
        },
        {
          text: 'Size',
          value: 'size',
          align: 'center'
        }
        // 'Pre-Order',
        // 'Pre-Order Note',
        // 'Cost',
        // 'Price',
        // 'Ratio',
        // ''
      ],
      rules: {
        textFill: {
          required: true
        },
        numberFill: {
          required: true,
          integer: true
        }
      }
    }
  },
  computed: {
    skusTable: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    removeSkusTable (val) {
      this.$emit('remove-skus-table', val)
    }
  }
}
</script>

<style scoped>

</style>
