<template>
  <section id="buy-the-look-input">
    <div class="buy-the-look-wrap">
      <draggable
        v-model="products"
        tag="div"
        class="buy-the-look-box"
        v-bind="dragOptions">
        <div
          v-for="(product, index) in products"
          :key="`product-preview-in-buy-the-look-${index}`">
          <product-preview-box
            :product="product"
            @on-remove="onRemoveItem(index)" />
        </div>
      </draggable>
      <div
        key="more-product-box"
        class="more-product-box"
        @click="moreProductClick()">
        <div>
          <div class="d-flex justify-center">
            <v-icon>
              mdi-plus-circle-outline
            </v-icon>
          </div>
          <span>
            Add Product
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Draggable from 'vuedraggable'
import ProductPreviewBox from './ProductPreviewBox.vue'

export default {
  components: {
    ProductPreviewBox,
    Draggable
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  computed: {
    products: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    moreProductClick () {
      this.$emit('more-product-click')
    },
    onRemoveItem (index) {
      this.$emit('on-remove', index)
    }
  }
}
</script>

<style scoped>
.buy-the-look-box {
  display: flex;
  flex-wrap: nowrap;
}
.buy-the-look-wrap {
  display: flex;
  height: 242px;
  border-radius: 6px;
  padding: 20px;
  border: 1px dashed rgba(0, 0, 0, 0.4);
  flex-wrap: nowrap;
  overflow-x: auto;
}
.draggable-items {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.more-product-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  min-width: 150px;
  height: 100%;
  border-radius: 6px;
  cursor: pointer;
  border: 1px dashed rgba(0, 0, 0, 0.4);
}

</style>
