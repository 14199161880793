<template>
  <div>
    <table class="size-charts-tabel mb-4">
      <tbody>
        <tr>
          <td></td>
          <td
            v-for="(sizeChart, headerIndex) in formData.sizeChart"
            :key="`head-sizeCharts-${headerIndex}`">
            <v-text-field
              v-model="sizeChart.header"
              color="secondary"
              append-icon="mdi-delete"
              outlined
              dense
              hide-details
              @input="insertHeaderColumn($event, headerIndex)"
              @click:append="deleteColumn(headerIndex)" />
          </td>
          <td :rowspan="keys.length + 1">
            <v-btn
              small
              fab
              color="secondary"
              class="ml-2"
              @click="addColumn()">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </td>
        </tr>
        <tr
          v-for="(key, keyIndex) in keys"
          :key="`mainkey-${keyIndex}`">
          <td>
            <v-text-field
              v-model="keys[keyIndex]"
              color="secondary"
              outlined
              dense
              hide-details
              @input="insertHeaderRow($event, keyIndex)" />
          </td>
          <td
            v-for="(chart, chartIndex) in formData.sizeChart"
            :key="`sizeCharts-${chartIndex}-${keyIndex}`">
            <v-text-field
              v-if="chartIndex === (formData.sizeChart.length - 1)"
              v-model="chart.rows[keyIndex].value"
              color="secondary"
              append-icon="mdi-delete"
              outlined
              dense
              hide-details
              @click:append="deleteRow(keyIndex)" />
            <v-text-field
              v-else
              v-model="chart.rows[keyIndex].value"
              color="secondary"
              outlined
              dense
              hide-details />
          </td>
        </tr>
        <tr>
          <td :colspan="keys.length">
            <div
              class="d-flex justify-center align-center"
              style="width: 100%;">
              <v-btn
                class="mt-2"
                small
                fab
                color="secondary"
                @click="addRow()">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <v-row>
      <v-col cols="6">
        <span class="field-label">
          Size Unit
        </span>
        <v-select
          v-model="formData.sizeUnit"
          :items="units"
          item-text="text"
          item-value="value"
          placeholder="โปรดเลือกหน่วยของ Size chart"
          color="secondary"
          outlined
          dense
          hide-details
          class="mt-2" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      units: [
        {
          text: 'Milimeter (มิลลิเมตร)',
          value: 'Milimeter'
        },
        {
          text: 'Centimeter (เซนติเมตร)',
          value: 'Centimeter'
        },
        {
          text: 'Inches (นิ้ว)',
          value: 'Inches'
        },
        {
          text: 'Meter (เมตร)',
          value: 'Meter'
        },
        {
          text: 'Milliliter (มิลลิลิตร)',
          value: 'Milliliter'
        }
      ]
    }
  },
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    keys () {
      const sizeCharts = this.formData.sizeChart

      if (!Array.isArray(sizeCharts)
        || sizeCharts.length === 0
        || !Array.isArray(sizeCharts[0].rows)
        || sizeCharts[0].rows.length === 0) {
        return []
      }
      return sizeCharts[0].rows.map((row) => row.key)
    }
  },
  methods: {
    addColumn () {
      const keys = this.formData.sizeChart[0].rows.map((row) => row.key)
      const mappedRows = keys.map((key) => ({
        key,
        value: ''
      }))

      this.formData.sizeChart.push({
        header: '',
        rows: mappedRows
      })
    },
    addRow () {
      this.formData.sizeChart.forEach((sizeChart) => {
        sizeChart.rows.push({
          key: '',
          value: ''
        })
      })
    },
    insertHeaderColumn (val, index) {
      this.formData.sizeChart[index].header = val
    },
    insertHeaderRow (val, index) {
      this.formData.sizeChart.forEach((sizeChart) => {
        sizeChart.rows.splice(index, 1, {
          key: val,
          value: sizeChart?.rows[index]?.value || ''
        })
      })
    },
    deleteColumn (index) {
      if (this.formData.sizeChart.length > 1) {
        this.formData.sizeChart.splice(index, 1)
      }
    },
    deleteRow (index) {
      this.formData.sizeChart.forEach((sizeChart) => {
        sizeChart.rows.splice(index, 1)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.size-charts-tabel {
  width: 100%;
}
.field-label {
  font-weight: bold;
}
</style>
